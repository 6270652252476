import useSWR from 'swr';
import { trpc } from '../config/trpc';

export type User = {
  firstName: string;
  lastName: string;
  name: string;
  userId: number;
  externalId: string;
  email: string;
  roles: (
    | 'ROLE_STORMPATH_USER'
    | 'ROLE_ADMIN_USER'
    | 'ROLE_PRE_AUTH_USER'
    | 'ROLE_PRE_AUTH_USER_NO_FACTOR'
    | 'ROLE_PRE_AUTH_ONBOARD_NO_FACTOR'
  )[];
  phone?: string;
  orgAccess: {
    canEdit: string[];
    canEditAll: boolean;
  };
  accountStatus:
    | 'ACTIVE'
    | 'INVITED'
    | 'MISSING_PHONE_NUMBER'
    | 'MISSING_MFA'
    | 'INVALID';
  customerData: {
    externalId: string;
    customerName: string;
  }[];
};

// Separate SWR cache keys for active vs. onboarding users as we don't want
// regular 401s to show up as errors during onboarding
export const USER_SWR_CACHE_KEY = 'loggedin-user-details';
export const ONBOARD_USER_SWR_CACHE_KEY = 'onboard-user';

export default function useCurrentUserData(
  sessionKey: string | null = USER_SWR_CACHE_KEY,
  authToken?: string
) {
  const { data, error, isValidating, mutate } = useSWR<User | undefined>(
    sessionKey,
    () =>
      trpc.user.getUserDetails.query(
        undefined,
        authToken
          ? {
              context: {
                token: authToken,
              },
            }
          : undefined
      ),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );
  return {
    data,
    error,
    isValidating,
    updateUserProfile: async (
      firstName: string,
      lastName: string,
      isChangePassword: boolean,
      oldPassword?: string,
      newPassword?: string
    ) => {
      if (firstName !== data?.firstName || lastName !== data?.lastName) {
        await trpc.user.updateUserProfile.mutate({
          firstName,
          lastName,
        });
      }
      if (isChangePassword) {
        await trpc.user.changePassword.mutate({
          oldPassword: oldPassword ?? '',
          newPassword: newPassword ?? '',
        });
      }
      mutate();
    },
  };
}

interface OnboardParams {
  shouldFetch: boolean;
  token?: string;
}

export function useOnboardUser({ shouldFetch, token }: OnboardParams) {
  const fetcher = async () => {
    const response = await trpc.onboard.getUserDetails.query(undefined, {
      context: {
        token,
      },
    });
    return response;
  };

  const { data, error } = useSWR(
    shouldFetch ? ONBOARD_USER_SWR_CACHE_KEY : null,
    fetcher,
    { revalidateOnFocus: false }
  );

  return { data, error, isLoading: !data && !error };
}
