import { createContext, useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router';

export interface ContextOrgType {
  orgId: string;
}

const OrgContext = createContext<ContextOrgType | null>(null);

export function useContextOrg(): ContextOrgType {
  const context = useContext(OrgContext);
  if (context === null) {
    throw new Error('Trying to use OrgContext outside of its provider');
  }
  return context;
}

export default function ContextOrg() {
  const { orgId, insightId } = useParams();
  if (typeof orgId !== 'string' && typeof insightId !== 'string') {
    throw new Error('Could not find orgId in params');
  }
  // FEATURE-NEWNAVIGATION: remove insightId when navigation is fully migrated
  const contextValue = useMemo(
    () => ({ orgId: (orgId ?? insightId)! }),
    [orgId, insightId]
  );
  return (
    <OrgContext.Provider value={contextValue}>
      <Outlet />
    </OrgContext.Provider>
  );
}
