import { createContext, useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router';
import { useContextOrg } from '../ContextOrg';
import useOrgRoutes, { RouteSummary } from '../../../data/useOrgRoutes';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../../utils/dataHandler';

export interface ContextRouteType {
  orgId: string;
  route?: RouteSummary;
}

const RouteContext = createContext<ContextRouteType | null>(null);

export function useContextRoute(): ContextRouteType {
  const context = useContext(RouteContext);
  if (context === null) {
    throw new Error('Trying to use RouteContext outside of its provider');
  }
  return context;
}

export default function ContextRoute() {
  const { orgId } = useContextOrg();
  const { routeId } = useParams();

  // TODO implement endpoint to get route details by id
  const projectRoutes = useOrgRoutes(orgId, [], {
    allRoutes: true,
  });
  const route = projectRoutes.data?.find(
    (projectRoute) => projectRoute.routeId === routeId
  );
  const contextValue = useMemo(() => ({ orgId, route }), [orgId, route]);

  if (hasDataHandlerFeedBack([projectRoutes])) {
    return <DataHandlerFeedback dataHandlersParam={[projectRoutes]} />;
  }
  return (
    <RouteContext.Provider value={contextValue}>
      <Outlet />
    </RouteContext.Provider>
  );
}
