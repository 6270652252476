import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import '../../styles/fonts.scss';
import '../../styles/styles.scss';
import ConfiguredChakraProvider from '../../config/chakra';
import { initTagManager } from '../../config/googleTagManager';
import { ConfiguredIntlProvider } from '../../config/intl';
import { initRaygun } from '../../config/raygun';
import '../../utils/local-storage-events';
import routes from './routes';

function initApp() {
  initTagManager();
  initRaygun();
  const container = document.getElementById('app');
  const root = createRoot(container);
  root.render(
    <ConfiguredIntlProvider>
      <ConfiguredChakraProvider>
        <RouterProvider router={routes} />
      </ConfiguredChakraProvider>
    </ConfiguredIntlProvider>
  );
}

initApp();
