import { ReactNode } from 'react';
import { Box, Flex, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import MoovenLogoMarkOnly from '../../svg/mooven-logomark.svg?react';
import { pathDashboard } from '../../constants/path';

type LayoutMultiPanelSidebarMode = 'narrow' | 'wide' | 'full';
export interface LayoutMultiPanelSettings {
  sidebarMode?: LayoutMultiPanelSidebarMode;
  addPadding?: boolean;
  showLogo?: boolean;
}
export interface LayoutMultiPanelProps {
  contentSidebar?: ReactNode;
  contentMain?: ReactNode;
  layoutSettings?: LayoutMultiPanelSettings;
}
const defaultLayoutMultiPanelSettings = {
  sidebarMode: 'narrow' as LayoutMultiPanelSidebarMode,
};

export default function LayoutMultiPanel({
  contentSidebar,
  contentMain,
  layoutSettings = defaultLayoutMultiPanelSettings,
}: LayoutMultiPanelProps) {
  const sidebarWidth = getSidebarWidth(layoutSettings.sidebarMode);
  const { addPadding = true, showLogo = true } = layoutSettings;
  return (
    <Flex
      padding={addPadding ? 6 : 0}
      gap={addPadding ? 6 : 4}
      width="100%"
      height="100%"
      direction={{ base: 'column', md: 'row' }}
    >
      <Box
        flex={{ base: '0 1 auto', md: `0 0 ${sidebarWidth}` }}
        maxWidth={{
          md: layoutSettings.sidebarMode !== 'full' ? '80%' : undefined,
        }}
        transition="flex-basis 0.3s"
        borderRadius="16px"
        backgroundColor="white"
        overflow="hidden"
        boxShadow="soft"
        height="auto"
        maxHeight={{ base: '70%', md: '100%' }}
      >
        <Flex direction="column" overflow="auto" height="100%">
          {showLogo && (
            <Box
              flex="0"
              padding={4}
              position={{ base: 'static', md: 'sticky' }}
              top={0}
              zIndex={1}
              backgroundColor="rgba(255, 255, 255, 0.9)"
              backdropFilter="blur(4px)"
            >
              <Link
                as={RouterLink}
                to={pathDashboard()}
                display="block"
                width="32px"
              >
                <MoovenLogoMarkOnly />
              </Link>
            </Box>
          )}
          <Box flex="1" padding={4}>
            {contentSidebar}
          </Box>
        </Flex>
      </Box>
      <Box
        flex="1 1 auto"
        backgroundColor="white"
        borderRadius="16px"
        overflow="hidden"
        minHeight="180px"
      >
        {contentMain}
      </Box>
    </Flex>
  );
}

function getSidebarWidth(sidebarMode?: LayoutMultiPanelSidebarMode) {
  switch (sidebarMode) {
    case 'wide':
      return '800px';
    case 'full':
      return '100%';
    case 'narrow':
    default:
      return '350px';
  }
}
