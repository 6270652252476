import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { checkboxAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

export default defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    control: {
      _readOnly: {
        opacity: 0.5,
      },
      backgroundColor: 'white',
    },
  }),
  defaultProps: {
    colorScheme: 'green',
  },
  sizes: {
    xl: definePartsStyle({
      control: defineStyle({
        boxSize: '20px',
      }),
      label: { fontSize: 'xs' },
      icon: { fontSize: '2xs' },
    }),
  },
});
