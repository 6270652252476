// import './Spinner.scss';
import { Spinner as ChakraSpinner } from '@chakra-ui/react';

export default function Spinner({
  size = 'md',
}: {
  size?: 'sm' | 'md' | 'lg' | 'xl';
}) {
  return <ChakraSpinner variant="mooven" size={size} verticalAlign="middle" />;
}
