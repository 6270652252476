import { pathSitesView } from '../constants/path';
import { CompareSortValuesMode } from '../utils/sort';
import useRouteMaxJourneyTimeChangeData from './useRouteMaxJourneyTimeChangeData';

export type RouteSummary = {
  enabled?: boolean;
  siteId: string;
  trackId: string;
  routeId: string;
  routeName?: string;
  siteName?: string;
  anomalyScore?: number | null;
  anomalyScorePercentage?: string;
  anomalyScoreColor?: string;
  wkt: string;
  siteUrl: string;
};

export interface OrgRoutesOptions {
  includeDisabled?: boolean;
  searchTerm?: string;
  sortBy?: keyof RouteSummary;
  sortDirection?: CompareSortValuesMode;
  allRoutes?: boolean;
}

export default function useOrgRoutes(
  insightId: string,
  sites: { siteId: string | number; siteName: string }[],
  options?: OrgRoutesOptions
): {
  data: RouteSummary[] | undefined;
  isLoading: boolean;
  error: any;
  isValidating: boolean;
} {
  const {
    includeDisabled = false,
    searchTerm = '',
    sortBy = 'routeName',
    sortDirection = CompareSortValuesMode.LOWEST_FIRST,
    allRoutes = false,
  } = options ?? {};
  const maxJourneyTimeChangesResult =
    useRouteMaxJourneyTimeChangeData(insightId);

  const trackIdsBySiteId: Record<number, string[]> =
    maxJourneyTimeChangesResult.data?.reduce((accumulator, current) => {
      if (current.siteId !== undefined && current.trackId !== undefined) {
        if (accumulator[current.siteId] === undefined) {
          accumulator[current.siteId] = [];
        }
        accumulator[current.siteId].push(current.trackId);
      }
      return accumulator;
    }, {}) ?? {};

  function siteUrl(route: { siteId: string; trackId: string }): string {
    const otherTrackIds = trackIdsBySiteId[route.siteId!].filter(
      (trackId) => trackId !== route.trackId
    );
    return `${pathSitesView(insightId)}?siteId=${
      route.siteId
    }&hidden=${otherTrackIds.join(',')}`;
  }

  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  const data = maxJourneyTimeChangesResult.data
    ?.filter((route) => route.enabled || includeDisabled)
    .filter((route) => {
      const site = sites.find((s) => `${s.siteId}` === `${route.siteId}`);
      return allRoutes || !!site;
    })
    .map((route) => {
      const site = sites.find((s) => `${s.siteId}` === `${route.siteId}`);
      return {
        ...route,
        siteName: site?.siteName,
      };
    })
    .filter(
      (route) =>
        lowerCaseSearchTerm === undefined ||
        route.routeName?.toLowerCase().includes(lowerCaseSearchTerm) ||
        route.siteName?.toLocaleLowerCase().includes(searchTerm)
    )
    .map((route) => {
      const anomalyScore = route.maxJourneyTimeChange;
      const anomalyScorePercentage = anomalyScore
        ? getAnomalyScorePercentage(anomalyScore)
        : undefined;
      const anomalyScoreColor = anomalyScore
        ? getAnomalyScoreColor(anomalyScore)
        : undefined;
      return {
        ...route,
        siteUrl: siteUrl(route),
        siteId: `${route.siteId}`,
        anomalyScore,
        anomalyScorePercentage,
        anomalyScoreColor,
      };
    });
  data?.sort((route1, route2) => {
    const value = sortValue(route1, route2, sortBy);
    return sortDirection === CompareSortValuesMode.LOWEST_FIRST
      ? value
      : -value;
  });
  return {
    ...maxJourneyTimeChangesResult,
    data,
  };
}

function sortValue(
  route1: {
    enabled?: boolean;
    siteId: string;
    trackId: string;
    routeName?: string;
    siteName?: string;
    anomalyScore?: number | null;
  },
  route2: {
    enabled?: boolean;
    siteId: string;
    trackId: string;
    routeName?: string;
    siteName?: string;
    anomalyScore?: number | null;
  },
  sortBy: keyof RouteSummary
): number {
  if (sortBy === 'anomalyScore') {
    return (route2.anomalyScore ?? 0) - (route1.anomalyScore ?? 0);
  }
  if ((route1[sortBy] ?? '') < (route2[sortBy] ?? '')) {
    return -1;
  }
  if ((route1[sortBy] ?? '') > (route2[sortBy] ?? '')) {
    return 1;
  }
  return 0;
}

export function getAnomalyScorePercentage(anomalyScore: number): string {
  return anomalyScore < 1
    ? `-${((1 - anomalyScore) * 100).toFixed(0)}%`
    : `+${((anomalyScore - 1) * 100).toFixed(0)}%`;
}

export function getAnomalyScoreColor(anomalyScore: number): string {
  if (anomalyScore >= 1.55) {
    return 'red.900';
  }
  if (anomalyScore >= 1.3) {
    return 'red.400';
  }
  if (anomalyScore >= 1.05) {
    return 'orange.300';
  }
  return 'blue.400';
}
