import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import { ReactNode, Ref } from 'react';

type FieldProps = Omit<FormControlProps, 'label'> & {
  labelText?: ReactNode;
  helperText?: ReactNode;
  errorText?: ReactNode;
  ref?: Ref<HTMLDivElement | null>;
};

export default function FormField(props: FieldProps) {
  const {
    children,
    labelText,
    size = 'xs',
    helperText,
    errorText,
    ref,
    ...rest
  } = props;
  return (
    <FormControl ref={ref} {...rest}>
      {labelText && <FormLabel fontSize={size}>{labelText}</FormLabel>}
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
    </FormControl>
  );
}
