import {
  Button,
  Heading,
  Text,
  VStack,
  Box,
  Flex,
  Stack,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import Insight from 'design-system/molecules/insight';
import { Fragment } from 'react/jsx-runtime';
import { ReactNode } from 'react';
import { ArrowGoIcon } from 'design-system/atoms/custom-icons';
import { Link as RouterLink } from 'react-router-dom';
import useSitesData from '../../data/useSitesData';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import RedLight from '../../svg/red-light.svg?react';
import StatImpactIcon from '../../svg/stat_impact.svg?react';
import { useContextRoute } from '../../router/context/ContextRoute';
import LayoutMultiPanel from '../LayoutMultiPanel';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import useRouteInsightsData from '../../data/useRouteInsightsData';
import useRoutePerformanceDataHandler from '../../data/useRoutePerformanceData';
import useAnnotationsData from '../../data/useAnnotationsData';
import RouteInsightChart from '../RouteInsights/RouteInsightChart';
import LayoutPanel from '../LayoutPanel';
import { getInsightPerformanceConfigFromType } from '../../utils/insightCharts';
import { pathSiteMonitoring } from '../../router/utils/path';

export function RouteDetails() {
  const { orgId, route } = useContextRoute();
  const { startSiteMonitoring } = useSitesData(orgId);
  const { formatMessage } = useIntl();

  const routePerformanceDataHandler = useRoutePerformanceDataHandler(
    route?.trackId ?? '',
    orgId,
    route?.siteId ?? ''
  );
  const routeInsightsDataHandler = useRouteInsightsData(
    route?.trackId ?? '',
    orgId,
    route?.siteId ?? ''
  );
  const annotationsData = useAnnotationsData(
    orgId,
    routeInsightsDataHandler.data?.siteId
  );

  const { track } = useAnalytics();
  const onEnableMonitoringButtonClick = async () => {
    if (route?.siteId !== undefined) {
      track('Enable Site Monitoring', {
        referrer: 'Route Details Page',
        siteId: route.siteId,
        orgId,
      });
      await startSiteMonitoring(`${route?.siteId}`);
    }
  };

  if (!route) {
    return (
      <MonitoringInactivePanel
        onEnableMonitoringClick={onEnableMonitoringButtonClick}
      />
    );
  }

  if (
    hasDataHandlerFeedBack([
      routePerformanceDataHandler,
      routeInsightsDataHandler,
      annotationsData,
    ])
  ) {
    return (
      <DataHandlerFeedback
        dataHandlersParam={[
          routePerformanceDataHandler,
          routeInsightsDataHandler,
          annotationsData,
        ]}
      />
    );
  }
  if (!routePerformanceDataHandler.data || !routeInsightsDataHandler.data) {
    return null;
  }
  return (
    <LayoutMultiPanel
      layoutSettings={{ addPadding: false, showLogo: false }}
      contentSidebar={
        <VStack
          height="100%"
          overflow="auto"
          w="full"
          gap={4}
          alignItems="flex-start"
        >
          <Flex justifyContent="flex-start" w="full">
            <Heading size="md">{route?.routeName}</Heading>
          </Flex>
          <Button
            size="sm"
            variant="outline"
            as={RouterLink}
            to={pathSiteMonitoring({ orgId, siteId: route.siteId })}
            isDisabled={route?.siteUrl === undefined}
            rightIcon={<ArrowGoIcon width="8px" />}
            mt={4}
            onClick={() => {
              track('Full Site Link Clicked', {
                referrer: 'Route Detail Page',
                siteId: route?.siteId,
                projectId: orgId,
              });
            }}
          >
            <FormattedMessage
              defaultMessage="Go to full site"
              id="5B2IfW"
              description="route performance modal - full site link label"
            />
          </Button>
          <Heading size="sm" mb={4}>
            Current performance
          </Heading>
          {routePerformanceDataHandler.data?.insights.map((insight) => {
            const insights: ReactNode[] = [];
            const insightPerformanceConfig =
              getInsightPerformanceConfigFromType(
                insight.insightType,
                { siteUnit: routePerformanceDataHandler.data?.siteUnit },
                formatMessage
              );
            if (
              insight.anomalyScore &&
              insightPerformanceConfig.colorForScoreValue &&
              insightPerformanceConfig.formatScoreValue
            ) {
              insights.push(
                <Insight
                  IconSVG={StatImpactIcon}
                  label={`Current ${insightPerformanceConfig.title.toLowerCase()}`}
                  title={
                    <Text
                      m={0}
                      color={insightPerformanceConfig.colorForScoreValue(
                        insight.anomalyScore
                      )}
                    >
                      {insightPerformanceConfig.formatScoreValue(
                        insight.anomalyScore
                      )}
                    </Text>
                  }
                  paragraph={formatMessage({
                    defaultMessage: 'compared to 4 week average',
                    id: 'eIzv6n',
                    description:
                      'Current route performance - stat diff disclaimer',
                  })}
                />
              );
            }
            if (insightPerformanceConfig.iconSVG) {
              insights.push(
                <Insight
                  IconSVG={insightPerformanceConfig.iconSVG}
                  label={`Current ${insightPerformanceConfig.title.toLowerCase()}`}
                  title={insightPerformanceConfig.formatValue(
                    insight.currentValue
                  )}
                />
              );
            }
            return insights;
          })}
        </VStack>
      }
      contentMain={
        <Flex
          height="100%"
          overflow="auto"
          w="full"
          flexDirection="column"
          alignItems="stretch"
        >
          {route?.enabled === true ? (
            routeInsightsDataHandler.data!.insights.map(
              (routeInsight) =>
                routeInsightsDataHandler.data && (
                  <Fragment key={routeInsight.insightType}>
                    <LayoutPanel w="full">
                      <RouteInsightChart
                        routeName={routeInsightsDataHandler.data.routeName}
                        siteUnit={routeInsightsDataHandler.data.siteUnit}
                        timeZone={routeInsightsDataHandler.data.timeZone}
                        routeInsight={routeInsight}
                        annotations={annotationsData.data}
                      />
                    </LayoutPanel>
                    {routeInsight.insightType === 'JOURNEY_TIME' &&
                      routeInsight.items.some((item) => item.compareValue) && (
                        <LayoutPanel>
                          <RouteInsightChart
                            routeName={routeInsightsDataHandler.data.routeName}
                            siteUnit={routeInsightsDataHandler.data.siteUnit}
                            timeZone={routeInsightsDataHandler.data.timeZone}
                            routeInsight={routeInsight}
                            annotations={annotationsData.data}
                            isChartModePercentage
                          />
                          <Stack
                            direction={{ base: 'column', sm: 'row' }}
                            justifyContent="center"
                            marginBottom={2}
                            spacing={1}
                            fontSize="xs"
                          >
                            <Text as="span" fontWeight={600} textAlign="center">
                              <FormattedMessage
                                id="+7mqEn"
                                defaultMessage="Journey time compared to last 4 weeks"
                                description="Routes chart legend title"
                              />
                            </Text>
                            <Flex justifyContent="center">
                              <Text as="span">
                                <FormattedMessage
                                  id="N0hDRV"
                                  defaultMessage="Expected"
                                  description="Route performance legend - expected"
                                />
                              </Text>
                              <Box display="inline-block" whiteSpace="nowrap">
                                {[
                                  '#D0E5F8',
                                  '#FDEAD5',
                                  '#FDD9D9',
                                  '#D8C5C6',
                                ].map((color) => (
                                  <Box
                                    key={color}
                                    marginLeft={1}
                                    display="inline-block"
                                    verticalAlign="middle"
                                    width={4}
                                    height="6px"
                                    borderRadius="3px"
                                    backgroundColor={color}
                                  />
                                ))}
                              </Box>
                              <Text as="span" marginLeft={1}>
                                <FormattedMessage
                                  id="5onLKa"
                                  defaultMessage="Slower"
                                  description="Route performance legend - slower"
                                />
                              </Text>
                            </Flex>
                          </Stack>
                        </LayoutPanel>
                      )}
                  </Fragment>
                )
            )
          ) : (
            <MonitoringInactivePanel
              onEnableMonitoringClick={onEnableMonitoringButtonClick}
            />
          )}
        </Flex>
      }
    />
  );
}

interface MonitoringInactivePanelProps {
  onEnableMonitoringClick: () => void;
}

function MonitoringInactivePanel({
  onEnableMonitoringClick,
}: MonitoringInactivePanelProps) {
  return (
    <VStack spacing={4} w="full">
      <Box maxWidth="md" height="md" marginTop={-8} marginBottom={-6}>
        <RedLight height="100%" width="100%" />
      </Box>
      <Heading maxWidth="md" textAlign="center">
        <FormattedMessage
          id="A7/aNv"
          defaultMessage="Monitoring is currently inactive on this route"
          description="Route detail modal - monitoring disabled heading"
        />
      </Heading>
      <Text maxWidth="md" textAlign="center">
        <FormattedMessage
          id="S3n0ia"
          defaultMessage="In order to view this route's current performance, turn on site-level monitoring."
          description="Route detail modal - monitoring disabled text"
        />
      </Text>
      <Button
        colorScheme="brand"
        variant="solid"
        onClick={onEnableMonitoringClick}
      >
        <FormattedMessage
          id="bYtOjR"
          defaultMessage="Turn on monitoring"
          description="Route detail modal - enable monitoring button"
        />
      </Button>
    </VStack>
  );
}
