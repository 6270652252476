import useSWR, { mutate as mutateGlobal } from 'swr';
import { SiteSummary } from '@webapp/bff/src/types/site';
import { BlankSchedule, Schedule } from '../types/schedule';
import { getSiteDetailsKey } from './sharedKeys';
import { trpc } from '../config/trpc';
import { errorReport } from '../utils/errors';

export default function useSitesData(insightId?: string): {
  data: Array<SiteSummary> | undefined;
  isValidating: boolean;
  error: any;
  forceValidate: () => void;
  createSite: (
    newSite: Partial<{
      siteName?: string;
      siteTimeZone?: string;
      siteAddress?: string;
      siteType?: string;
      siteUnit?: 'IMPERIAL' | 'METRIC';
      location?: { lat: number; lng: number };
      delayKPI?: number;
      speedKPI?: number;
      queueKPI?: number;
    }>
  ) => Promise<number>;
  deleteSite: (siteId: string) => Promise<void>;
  updateSiteProps: (
    siteId: string,
    updatedSiteProps: {
      siteName: string;
      siteTimeZone: string;
      siteAddress: string;
      siteUnit: 'IMPERIAL' | 'METRIC';
      siteDescription: string;
      delayKPI?: number;
      speedKPI?: number;
      queueKPI?: number;
      siteLocation?: { lat: number; lng: number };
    }
  ) => Promise<void>;
  startSiteMonitoring: (siteId: string) => Promise<void>;
  stopSiteMonitoring: (siteId: string) => Promise<void>;
  saveSiteSchedule: (
    siteId: string,
    scheduleData: BlankSchedule | Schedule
  ) => Promise<void>;
  deleteSiteSchedule: (siteId: string, scheduleId: string) => Promise<void>;
} {
  const { data, mutate, isValidating, error } = useSWR<Array<SiteSummary>>(
    insightId
      ? {
          keyName: 'useSitesData',
          insightId,
        }
      : null,
    async (key) => {
      const sites = await trpc.site.getSiteSummaries.query({
        orgId: key.insightId,
      });
      return sortSites(sites);
    }
  );
  const sitebySiteId = (siteId) => data?.find((site) => site.siteId === siteId);

  const updateLocalCache = async (siteId) => {
    if (insightId) {
      return Promise.all([
        mutateGlobal(getSiteDetailsKey(insightId, siteId)),
        mutate(),
      ]);
    }
    return undefined;
  };

  const addSiteData = (siteId: string) => {
    if (insightId) {
      return Promise.all([
        mutateGlobal(getSiteDetailsKey(insightId, siteId)),
        mutate(),
      ]);
    }
    return undefined;
  };

  const deleteSiteSchedule = async (siteId: string, scheduleId: string) => {
    await trpc.monitoring.stopSchedule.mutate({
      siteId: `${siteId}`,
      scheduleId: `${scheduleId}`,
    });
    await updateLocalCache(siteId);
  };
  const createSiteSchedule = async (
    siteId: string,
    scheduleData: BlankSchedule
  ) => {
    await trpc.monitoring.createSchedule.mutate({
      siteId: `${siteId}`,
      startTime: scheduleData.startTime,
      endTime: scheduleData.endTime,
      scheduleName: scheduleData.scheduleName ?? 'Current Schedule',
    });
    await updateLocalCache(siteId);
  };

  const editSiteSchedule = async (
    siteId: string,
    updatedSchedule: Schedule
  ) => {
    await trpc.monitoring.updateSchedule.mutate({
      siteId: `${siteId}`,
      scheduleId: updatedSchedule.scheduleId,
      startTime: updatedSchedule.startTime,
      endTime: updatedSchedule.endTime,
    });
    await updateLocalCache(siteId);
  };
  const saveSiteSchedule = (
    siteId: string,
    scheduleData: BlankSchedule | Schedule
  ) =>
    (scheduleData as any).scheduleId
      ? editSiteSchedule(siteId, scheduleData as Schedule)
      : createSiteSchedule(siteId, scheduleData as BlankSchedule);

  const mutateThenUpdate =
    (serverOp) =>
    async (
      ...args: Parameters<typeof serverOp>
    ): Promise<ReturnType<typeof serverOp>> => {
      const response = await serverOp(...args);
      await mutate();
      return response;
    };

  return {
    data,
    isValidating,
    error,
    forceValidate: () => mutate(),
    createSite: async (newSiteProps) => {
      if (!insightId) {
        return Promise.reject(new Error('Error: No insight ID provided'));
      }
      const siteId = await trpc.site.createNewSite.mutate({
        ...newSiteProps,
        siteAddress: newSiteProps.siteAddress!,
        siteName: newSiteProps.siteName!,
        siteTimeZone: newSiteProps.siteTimeZone!,
        siteType: newSiteProps.siteType!,
        siteUnit: newSiteProps.siteUnit!,
        siteLocation: {
          lat: newSiteProps!.location!.lat!,
          lng: newSiteProps!.location!.lng!,
        },
        insightId,
      });
      await addSiteData(`${siteId}`);
      return siteId;
    },
    deleteSite: async (siteId) => {
      if (!insightId) {
        return Promise.reject(new Error('Error: No insight ID provided'));
      }
      return mutateThenUpdate(trpc.site.deleteSite.mutate)({
        siteId: `${siteId}`,
        insightId,
      });
    },
    updateSiteProps: async (
      siteId,
      newSiteProps: {
        siteName: string;
        siteTimeZone: string;
        siteAddress: string;
        siteUnit: 'IMPERIAL' | 'METRIC';
        siteDescription: string;
        delayKPI?: number;
        speedKPI?: number;
        queueKPI?: number;
        siteLocation?: { lat: number; lng: number };
      }
    ) => {
      if (!insightId) {
        throw new Error('Error: No insight ID provided');
      }
      // PUT request will override monitoring status if not sent with payload
      const siteData = sitebySiteId(siteId);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { routes, ...rest } = siteData || {};
      const updatedSiteData = { ...rest, ...newSiteProps };
      try {
        await trpc.site.updateSiteDetails.mutate({
          siteId: `${siteId}`,
          title: updatedSiteData.siteName ?? '',
          timezone: updatedSiteData.siteTimeZone,
          address: updatedSiteData.siteAddress,
          unitSystem: updatedSiteData.siteUnit,
          description: updatedSiteData.siteDescription,
          location: updatedSiteData.siteLocation,
          kpi: {
            delay: updatedSiteData.delayKPI,
            speed: updatedSiteData.speedKPI,
            queue: updatedSiteData.queueKPI,
          },
        });
      } catch (ex) {
        errorReport.critical(ex, { referrer: 'New update site details' });
      }
      await updateLocalCache(siteId);
    },

    startSiteMonitoring: async (siteId) => {
      await trpc.monitoring.startMonitoring.mutate({ siteId: `${siteId}` });
      if (insightId) {
        await mutateGlobal(getSiteDetailsKey(insightId, siteId));
      }
      await updateLocalCache(siteId);
    },
    stopSiteMonitoring: async (siteId) => {
      await trpc.monitoring.stopMonitoring.mutate({ siteId: `${siteId}` });
      if (insightId) {
        await mutateGlobal(getSiteDetailsKey(insightId, siteId));
      }
      await updateLocalCache(siteId);
    },
    saveSiteSchedule,
    deleteSiteSchedule,
  };
}

export function sortSites(sitesArray: Array<SiteSummary> = []) {
  return sitesArray.slice().sort((siteA, siteB) => {
    if (siteA.active && !siteB.active) {
      return -1;
    }
    if (!siteA.active && siteB.active) {
      return 1;
    }
    if (siteA.active && siteB.active) {
      const nextEndDateA = (siteA.schedules || []).reduce(
        getNextEndDate,
        new Date(1000)
      );
      const nextEndDateB = (siteB.schedules || []).reduce(
        getNextEndDate,
        new Date(1000)
      );
      if (nextEndDateA !== nextEndDateB) {
        return (nextEndDateA?.getTime() ?? 0) - (nextEndDateB?.getTime() ?? 0);
      }
    } else if (!siteA.active && !siteB.active) {
      const nextStartDateA = (siteA.schedules || []).reduce(
        getNextStartDate,
        new Date(1000)
      );
      const nextStartDateB = (siteB.schedules || []).reduce(
        getNextStartDate,
        new Date(1000)
      );
      if (nextStartDateA !== nextStartDateB) {
        return (
          (nextStartDateA?.getTime() ?? 0) - (nextStartDateB?.getTime() ?? 0)
        );
      }
    }
    const nameA = (siteA.siteName || '').toUpperCase();
    const nameB = (siteB.siteName || '').toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}
function getNextEndDate(
  nextEndDate: Date | undefined,
  schedule: Schedule
): Date | undefined {
  if (!schedule.endTime || schedule.endTime.getTime() > Date.now()) {
    return !nextEndDate
      ? schedule.endTime
      : new Date(
          Math.min(
            nextEndDate.getTime(),
            schedule.endTime?.getTime() ?? nextEndDate.getTime() + 10000
          )
        );
  }
  return nextEndDate;
}
function getNextStartDate(nextStartDate: Date, schedule: Schedule): Date {
  if (schedule.startTime.getTime() > nextStartDate.getTime()) {
    return schedule.startTime;
  }
  return nextStartDate;
}
