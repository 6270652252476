import { ReactNode, useState } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';
import { useNavigate } from 'react-router-dom';
import { SiteType } from './types';
import SiteTypeSelector from './SiteTypeSelector';
import { pathSitesCreate } from '../../constants/path';

export default function CreateSiteTrigger({
  insightId,
  renderButton = (onClick) => (
    <Button colorScheme="greenDark" onClick={onClick}>
      <FormattedMessage defaultMessage="Create site" id="psYTXj" />
    </Button>
  ),
}: {
  insightId: string;
  renderButton?: (onClick: () => void) => ReactNode;
}) {
  const {
    isOpen,
    onOpen,
    onClose,
    selectedSiteType,
    setSelectedSiteType,
    onSubmit,
  } = useCreateSiteTrigger({ orgId: insightId });
  return (
    <>
      {renderButton(onOpen)}
      <CreateSiteTriggerModal
        selectedSiteType={selectedSiteType}
        setSelectedSiteType={setSelectedSiteType}
        onSubmit={onSubmit}
        onClose={onClose}
        isOpen={isOpen}
      />
    </>
  );
}

export function CreateSiteTriggerModal({
  selectedSiteType,
  setSelectedSiteType,
  onSubmit,
  onClose,
  isOpen,
}: {
  selectedSiteType: SiteType;
  setSelectedSiteType: (newValue: SiteType) => void;
  onSubmit: () => void;
  onClose: () => void;
  isOpen: boolean;
}) {
  const { formatMessage } = useIntl();
  return (
    <ConfirmationModal
      open={isOpen}
      onCancel={onClose}
      title={formatMessage({
        defaultMessage: 'Create a new area to monitor',
        id: '8Wjaea',
      })}
      confirmButtonText={formatMessage({
        defaultMessage: 'Next',
        id: '9+Ddtu',
      })}
      body={
        <SiteTypeSelector
          value={selectedSiteType}
          onChange={setSelectedSiteType}
        />
      }
      onConfirm={onSubmit}
    />
  );
}

export function useCreateSiteTrigger({
  orgId,
}: {
  orgId: string;
  renderButton?: (onClick: () => void) => ReactNode;
}) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSiteType, setSelectedSiteType] = useState<SiteType>(
    SiteType.PROJECT_SITE
  );
  return {
    isOpen,
    onOpen,
    onClose,
    selectedSiteType,
    setSelectedSiteType,
    onSubmit: () => {
      navigate(pathSitesCreate(orgId, fragmentFromsiteType(selectedSiteType)));
      onClose();
    },
  };
}

function fragmentFromsiteType(siteType: SiteType): 'project' | 'network' {
  if (siteType === SiteType.MONITORED_NETWORK) {
    return 'network';
  }
  return 'project';
}
