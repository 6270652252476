import useSWRInfinite from 'swr/infinite';
import { useCallback, useMemo } from 'react';
import {
  createAnnotation,
  deleteAnnotation,
  updateAnnotation,
  resendAnnotationAlert,
} from '../api/annotation';
import { dataTrpc } from '../config/trpc';

const PAGE_SIZE = 30;

export type Annotation = {
  ownerName?: string;
  canEdit?: boolean;
  annotationId?: number | undefined;
  title?: string;
  description?: string | undefined;
  startDate?: number;
  endDate?: number | undefined;
  createDate?: number;
  creatorId?: string | undefined;
  siteId?: number;
  sendAlert?: boolean;
};
export interface AnnotationsDataHandler {
  data: Annotation[];
  error: any;
  isValidating: boolean;
  isReachingEnd: boolean;
  size: number;
  setSize: (size: number | ((_size: number) => number)) => void;
  createAnnotation: (newAnnotation: Partial<Annotation>) => Promise<void>;
  updateAnnotation: (editedAnnotation: Annotation) => Promise<void>;
  deleteAnnotation: (annotationToDelete: Annotation) => Promise<void>;
  resendAnnotationAlert: (annoatationId: number) => Promise<void>;
}

export default function useAnnotationsData(
  insightId,
  siteId
): AnnotationsDataHandler {
  const getAnnotationKey = (pageIndex) =>
    !siteId
      ? null
      : {
          keyName: 'useAnnotationsData',
          siteId,
          insightId,
          page: pageIndex + 1,
          pageSize: PAGE_SIZE,
        };

  const { data, error, isValidating, mutate, setSize, size } = useSWRInfinite<
    Annotation[]
  >(
    getAnnotationKey,
    async (swrParams) =>
      dataTrpc.annotations.getAnnotations.query({
        page: `${swrParams.page}`,
        pageSize: `${swrParams.pageSize}`,
        siteId: `${swrParams.siteId}`,
      }),
    {
      revalidateOnFocus: false,
      refreshInterval: 10 * 1000,
    }
  );
  const allPagesData = useMemo(
    () => (data ? ([] as Annotation[]).concat(...data) : []),
    [data]
  );
  return {
    data: allPagesData,
    error,
    isValidating,
    isReachingEnd: data ? data[data.length - 1]?.length < PAGE_SIZE : false,
    size,
    setSize,
    createAnnotation: useCallback(
      (newAnnotation) =>
        createAnnotation(insightId, siteId, newAnnotation).then(async () => {
          await mutate();
        }),
      [mutate, insightId, siteId]
    ),
    updateAnnotation: useCallback(
      (editedAnnotation) => {
        if (editedAnnotation.annotationId === undefined) {
          return Promise.reject(
            new Error('Annotation ID is required to update an annotation')
          );
        }
        // TS doesn't know that the annotationId has been checked for undefined
        // @ts-ignore
        return updateAnnotation(insightId, siteId, editedAnnotation).then(
          () => {
            mutate((asyncData) => {
              if (!asyncData) {
                return undefined;
              }
              return asyncData.map((page) =>
                page.map((annotation) => {
                  if (
                    annotation.annotationId === editedAnnotation.annotationId
                  ) {
                    return editedAnnotation;
                  }
                  return annotation;
                })
              );
            });
          }
        );
      },
      [insightId, siteId, mutate]
    ),
    deleteAnnotation: useCallback(
      (annotationToDelete) => {
        if (annotationToDelete.annotationId === undefined) {
          return Promise.reject(
            new Error('Annotation ID is required to update an annotation')
          );
        }
        // TS doesn't know that the annotationId has been checked for undefined
        // @ts-ignore
        return deleteAnnotation(insightId, siteId, annotationToDelete).then(
          () => {
            mutate((asyncData) => {
              if (!asyncData) {
                return undefined;
              }
              return asyncData.map((page) =>
                page.filter(
                  (annotation) =>
                    annotation.annotationId !== annotationToDelete.annotationId
                )
              );
            });
          }
        );
      },
      [insightId, siteId, mutate]
    ),
    resendAnnotationAlert: useCallback(
      async (annotationId: number) => {
        await resendAnnotationAlert(insightId, siteId, annotationId);
      },
      [insightId, siteId]
    ),
  };
}
