import { ReactNode } from 'react';
import { As, Flex, Tag } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

export interface InsightProps {
  IconSVG: As | undefined;
  label?: ReactNode;
  title?: ReactNode;
  summary?: ReactNode;
  paragraph?: ReactNode;
  actionLabel?: ReactNode;
  actionLink?: string;
}

export default function Insight({
  IconSVG,
  label,
  title,
  summary,
  paragraph,
  actionLabel,
  actionLink,
}: InsightProps) {
  return (
    <div className={styles.root}>
      <Flex
        width="32px"
        height="32px"
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
        backgroundColor="gray.50"
      >
        <Icon as={IconSVG} color="gray.600" boxSize="20px" />
      </Flex>
      <div className={styles.infoContainer}>
        {label && <Tag size="sm">{label}</Tag>}
        {title && <div className={styles.title}>{title}</div>}
        {summary && <div className={styles.summary}>{summary}</div>}
        {paragraph && <div className={styles.paragraph}>{paragraph}</div>}
        {actionLabel && !actionLink && (
          <div className={styles.actionButton}>{actionLabel}</div>
        )}
        {actionLabel && actionLink && (
          <Link className={styles.actionButton} to={actionLink}>
            {actionLabel}
          </Link>
        )}
      </div>
    </div>
  );
}
