import { ScopedMutator } from 'swr/_internal';
import { logout } from '../api/auth';

export async function clearUserData(mutate: ScopedMutator) {
  // update all SWR keys to undefined (clear SWR cache)

  await mutate(() => true, undefined, { revalidate: false });
  localStorage.setItem('session-id', `${Math.floor(Math.random() * 100)}`);
}

export async function performLogout(mutate: ScopedMutator) {
  await clearUserData(mutate);
  return logout();
}
