import useSWR from 'swr';
import { RoutePerformance } from '@webapp/bff/src/types/route-insights';
import { dataTrpc } from '../config/trpc';

export interface RoutePerformanceDataHandler {
  data: RoutePerformance | undefined;
  error: any;
  isValidating: boolean;
}

export default function useRoutePerformanceDataHandler(
  trackId: string,
  insightId: string,
  siteId: string
): RoutePerformanceDataHandler {
  const shouldFetch = trackId && siteId;
  const { data, error, isValidating } = useSWR<RoutePerformance>(
    shouldFetch ? `useRoutePerformanceData/${insightId}/${trackId}` : null,
    () =>
      dataTrpc.routePerformance.get.query({
        passageId: trackId,
        orgId: insightId,
        siteId: `${siteId}`,
      }) as Promise<RoutePerformance>,
    {
      refreshInterval: 5 * 60 * 1000,
    }
  );
  return {
    data: shouldFetch ? data : undefined,
    error,
    isValidating,
  };
}
