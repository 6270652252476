import { Button, HStack, IconButton } from '@chakra-ui/react';
import { useContext } from 'react';
import { SettingsIcon } from '@chakra-ui/icons';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation, useParams } from 'react-router-dom';
import ContextMenu, {
  ContextMenuItemConfig,
} from 'design-system/molecules/context-menu';
import { mainNavigationMenuItems } from '../../../constants/mainNavigationMenuItems';
import useCurrentUserData from '../../../data/useCurrentUserData';
import CreateSiteTrigger from '../../../components/CreateSiteTrigger';
import SmartVMSTrigger from '../../../components/SmartVMSTrigger';
import { useOrgPermissions } from '../../../hooks/useOrgPermissions';
import { pathImpactAssessmentRequest } from '../../../constants/path';
import { AuthContext } from '../../../router/context/ContextAuth/types';

interface MainNavigationProps {
  showCreateSiteButton?: boolean;
}

const menuButtonStyleProps = {
  size: { base: 'sm', md: 'md' },
  variant: 'solid',
  colorScheme: 'gray',
  color: 'gray.700',
  layerStyle: 'floating',
  backgroundColor: 'white',
};

export default function MainNavigation({
  showCreateSiteButton = true,
}: MainNavigationProps) {
  const { formatMessage } = useIntl();
  const { insightId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const siteId = queryParams.get('siteId');

  const { user } = useContext(AuthContext);
  const currentUser = useCurrentUserData();
  const { data: permissions } = useOrgPermissions(insightId);

  const dropdownItems = mainNavigationMenuItems(
    insightId ?? null,
    user?.isAdmin ?? false,
    currentUser.data?.orgAccess?.canEditAll ||
      (currentUser.data?.orgAccess?.canEdit ?? []).indexOf(`${insightId}`) >= 0
  );

  const menuItems: ContextMenuItemConfig[] = dropdownItems.map((item) => ({
    key: formatMessage(item.label),
    label: formatMessage(item.label),
    action: item.path,
  }));

  return (
    <HStack>
      {showCreateSiteButton && insightId && (
        <CreateSiteTrigger
          insightId={insightId}
          renderButton={(onClick) => (
            <Button
              data-testid="create-site-nav-button"
              {...menuButtonStyleProps}
              onClick={onClick}
              isDisabled={
                !permissions || !permissions.includes('CanCreateSite')
              }
            >
              <FormattedMessage
                defaultMessage="New site"
                id="nuxa+S"
                description="Main navigation New site button label"
              />
            </Button>
          )}
        />
      )}

      {insightId && siteId && (
        <Button
          {...menuButtonStyleProps}
          as={Link}
          to={pathImpactAssessmentRequest(insightId, siteId)}
        >
          <FormattedMessage
            defaultMessage="Get impact assessment"
            id="rSTVMo"
          />
        </Button>
      )}

      <SmartVMSTrigger />

      <ContextMenu
        items={menuItems}
        triggerButtonProps={{
          ...menuButtonStyleProps,
          maxW: '42px',
          verticalAlign: 'middle',
          _hover: {
            backgroundColor: 'gray.50',
          },
          'aria-label': formatMessage(
            defineMessage({
              defaultMessage: 'Menu',
              id: 'gCxaIp',
              description: 'Aria label for the main navigation menu button',
            })
          ),
          as: IconButton,
          icon: <SettingsIcon />,
          rightIcon: undefined,
        }}
      />
    </HStack>
  );
}
