import useSWR from 'swr';
import { BlankRoute, Route } from '../types/route';
import { trpc } from '../config/trpc';

export default function useRoutesData(siteId: string | number | undefined): {
  data: Array<Route> | undefined;
  isValidating: boolean;
  error: any;
  forceValidate: () => void;
  createRoute: (newRoute: BlankRoute) => Promise<Route>;
  editRoute: (route: Route) => Promise<Route>;
  deleteRoute: (route: Route) => Promise<void>;
} {
  const { data, mutate, isValidating, error } = useSWR<Route[]>(
    siteId !== undefined
      ? {
          keyName: 'useRoutesData',
          siteId,
        }
      : null,
    (args) => {
      if (args === null) {
        return Promise.reject(new Error('useRoutesData args cannot be null'));
      }

      return trpc.siteRoute.getSiteRoutes.query({
        siteId: `${args.siteId}`,
      });
    }
  );

  const mutateThenUpdate =
    (serverOp) =>
    async (
      ...args: Parameters<typeof serverOp>
    ): Promise<ReturnType<typeof serverOp>> => {
      const response = await serverOp(...args);
      await mutate();
      return response;
    };

  return {
    data,
    isValidating,
    error,
    forceValidate: () => mutate(),
    editRoute: (route: Route) => {
      if (siteId === undefined) {
        throw new Error('Must provide a site ID to edit route.');
      }
      const newRouteProps = !route.monitorStarted
        ? route
        : {
            siteId: route.siteId,
            routeId: route.routeId,
            name: route.name,
            description: route.description,
            queuing: route.queuing,
          };
      return mutateThenUpdate(trpc.siteRoute.editSiteRoute.mutate)({
        ...newRouteProps,
      });
    },
    deleteRoute: (route: Route) => {
      if (siteId === undefined) {
        throw new Error('Must provide a site ID to delete route.');
      }
      return mutateThenUpdate(trpc.siteRoute.deleteSiteRoute.mutate)({
        routeId: route.routeId,
        siteId,
      });
    },
    createRoute: (newRoute: BlankRoute) => {
      if (siteId === undefined) {
        throw new Error('Must provide a site ID to create route.');
      }

      return mutateThenUpdate(trpc.siteRoute.createSiteRoute.mutate)({
        ...newRoute,
        siteId,
        startPoint: newRoute.startPoint!,
        endPoint: newRoute.endPoint!,
      });
    },
  };
}
