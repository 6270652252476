import { dataTrpc } from '../config/trpc';

export function createAnnotation(
  insightId: string,
  siteId: number,
  annotation: Partial<{
    title: string;
    description: string;
    startDate: number;
    endDate: number;
    sendAlert: boolean;
  }>
) {
  return dataTrpc.annotations.createAnnotation.mutate({
    orgId: `${insightId}`,
    siteId: `${siteId}`,
    title: annotation.title ?? '',
    description: annotation.description,
    startDate: annotation.startDate!,
    endDate: annotation.endDate,
    sendAlert: annotation.sendAlert,
  });
}
export function updateAnnotation(
  insightId: string,
  siteId: number,
  annotation: Required<{
    annotationId: string;
    title: string;
    description: string;
    startDate: number;
    endDate: number;
    sendAlert: boolean;
  }>
) {
  return dataTrpc.annotations.updateAnnotation.mutate({
    orgId: `${insightId}`,
    siteId: `${siteId}`,
    annotationId: `${annotation.annotationId}`,
    title: annotation.title,
    description: annotation.description,
    startDate: annotation.startDate,
    endDate: annotation.endDate,
    sendAlert: annotation.sendAlert,
  });
}
export function deleteAnnotation(
  insightId: string,
  siteId: number,
  annotation: Required<{
    annotationId: string;
  }>
) {
  return dataTrpc.annotations.deleteAnnotation.mutate({
    siteId: `${siteId}`,
    annotationId: `${annotation.annotationId}`,
  });
}

export function resendAnnotationAlert(
  insightId: number,
  siteId: number,
  annotationId: number
) {
  return dataTrpc.annotations.resendAnnotationAlert.mutate({
    annotationId: `${annotationId}`,
    siteId: `${siteId}`,
    orgId: `${insightId}`,
  });
}
