export enum NightDayPeriod {
  DAY = 'DAY',
  NIGHT = 'NIGHT',
}
export enum PredictionMode {
  AVERAGE = 'AVERAGE',
  PESSIMISTIC = 'PESSIMISTIC',
}
export enum KpiBreachType {
  OVER = 'OVER',
  UNDER = 'UNDER',
}
export type PlanInsightType = 'delay' | 'time' | 'speed' | 'impact' | 'count';
export type PlanConstraints = {
  startTime: TimeString | '';
  endTime: TimeString | '';
  kpi: number | undefined;
  showConstraints: boolean;
};
export type PlanSettingsParams = {
  startDate?: number;
  endDate?: number;
  daysOfTheWeek: number[];
  period: NightDayPeriod;
  mode: PredictionMode;
};

export type TimeString = `${number}:${number}`;
export function isTimeString(value: unknown): value is TimeString {
  if (typeof value !== 'string') return false;
  try {
    const timeChunks = value.split(':');
    const hour = Number(timeChunks[0]);
    const minute = Number(timeChunks[1]);
    if (hour >= 0 && hour < 24 && minute >= 0 && minute < 60) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
}
