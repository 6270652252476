import useSWR from 'swr';
import { trpc } from '../config/trpc';

export interface SiteUserView {
  viewId: number;
  viewName: string;
  isSiteLevel: boolean;
  isDefault: boolean;
  hiddenRoutes: string[];
  timePeriod:
    | 'TIME_PERIOD_LAST_24_HOURS'
    | 'TIME_PERIOD_LAST_WEEK'
    | 'TIME_PERIOD_LAST_MONTH'
    | 'TIME_PERIOD_CUSTOM';
  startDate?: Date;
  endDate?: Date;
  isEditable: boolean;
}

export default function useSiteUserViewData(params: null | { siteId: string }) {
  const { data, error, isValidating, mutate } = useSWR<SiteUserView[]>(
    params?.siteId ? `site-views-${params.siteId}` : null,
    () => trpc.site.getSharedViews.query({ siteId: params?.siteId ?? '' })
  );

  return {
    data:
      data &&
      data.map((v) => ({
        ...v,
        queryString: `hidden=${v.hiddenRoutes.join(
          ','
        )}&timePeriod=${v.timePeriod}&startDate=${v.startDate?.getTime() ?? ''}&endDate=${v.endDate?.getTime() ?? ''}`,
      })),
    error,
    isValidating,
    createView: async (view: {
      viewName?: string;
      hidden?: string;
      isSiteLevel?: boolean;
      timePeriod?:
        | 'TIME_PERIOD_LAST_24_HOURS'
        | 'TIME_PERIOD_LAST_WEEK'
        | 'TIME_PERIOD_LAST_MONTH'
        | 'TIME_PERIOD_CUSTOM';
      startDate?: number;
      date?: number;
    }) => {
      if (!params) {
        throw new Error('Site ID is required to create a view');
      }
      const response = await trpc.site.createSharedView.mutate({
        siteId: params.siteId,
        viewName: view.viewName ?? '',
        hiddenRoutes: view.hidden?.split(',') ?? [],
        isSiteLevel: view.isSiteLevel ?? false,
        timePeriod: view.timePeriod ?? 'TIME_PERIOD_LAST_24_HOURS',
        startDate: view.startDate ? new Date(view.startDate) : undefined,
        endDate: view.date ? new Date(view.date) : undefined,
      });
      await mutate();
      return response;
    },
    deleteView: async (viewId: string) => {
      if (!params) {
        throw new Error('Site ID is required to delete a view');
      }
      const response = await trpc.site.deleteSharedView.mutate({
        viewId: parseInt(viewId, 10),
        siteId: params.siteId,
      });
      await mutate();
      return response;
    },
    updateView: async (
      viewId: string,
      config: {
        viewName?: string;
        isSiteLevel?: boolean;
      }
    ) => {
      if (!params) {
        throw new Error('Site ID is required to update a view');
      }
      const response = await trpc.site.updateSharedViewNameAndSiteLevel.mutate({
        viewId: parseInt(viewId, 10),
        viewName: config.viewName ?? '',
        isSiteLevel: config.isSiteLevel ?? false,
        siteId: params.siteId,
      });
      await mutate();
      return response;
    },
    setDefaultView: async (
      viewId: string,
      config: {
        isDefault?: boolean;
      }
    ) => {
      if (!params) {
        throw new Error('Site ID is required to set a default view');
      }
      const response = await trpc.site.updateSharedViewDefaultStatus.mutate({
        viewId: parseInt(viewId, 10),
        siteId: params.siteId,
        isDefault: config.isDefault ?? false,
      });
      await mutate();
      return response;
    },
  };
}
