import '../styles/fonts.css';
import { extendTheme } from '@chakra-ui/react';
import Avatar from './Avatar';
import Button from './Button';
import Card from './Card';
import Checkbox from './Checkbox';
import Drawer from './Drawer';
import Input from './Input';
import Link from './Link';
import Modal from './Modal';
import Radio from './Radio';
import Spinner from './Spinner';
import Tabs from './Tabs';
import Select from './Select';
import Tooltip from './Tooltip';
import Table from './Table';
import Alert from './Alert';
import Textarea from './Textarea';
import Popover from './Popover';

export const defaultTheme = extendTheme({
  breakpoints: {
    '3xl': '120em',
  },
  shadows: {
    soft: '0px 4px 16px 0px rgba(0,0,0,0.06)',
  },
  colors: {
    black: '#222',
    brand: {
      50: '#EEFAF2',
      100: '#D6F7DE',
      200: '#B9F6CA',
      300: '#9DE8B0',
      400: '#67DC95',
      500: '#007C49',
      600: '#005C3A',
      700: '#00482B',
      800: '#003821',
      900: '#002d1b',
    },
    green: {
      50: '#EEFAF2',
      100: '#D6F7DE',
      200: '#B9F6CA',
      300: '#9DE8B0',
      400: '#67DC95',
      500: '#00C853',
      600: '#00A042',
      700: '#007C49',
      800: '#005C3A',
      900: '#00482B',
    },
    greenDark: {
      500: '#007C49',
      600: '#005C3A',
      700: '#00482B',
    },
    gray: {
      50: '#F6F7F9',
      100: '#EEF2F5',
      200: '#EAEEF2',
      300: '#D4DBE2',
      400: '#ACB5BF',
      500: '#7E8590',
      600: '#56586B',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
    },
  },
  fonts: {
    heading: `'MoovenFont', sans-serif`,
    body: `'MoovenFont', sans-serif`,
  },
  lineHeights: {
    base: 'normal',
  },
  styles: {
    global: {
      body: {
        bg: '#f7f7f7',
        color: 'gray.700',
      },
      svg: {
        display: 'inline',
      },
      'h1,h2,h3,h4,h5,h6': {
        fontWeight: 'revert',
        fontSize: 'revert',
        color: 'gray.900',
      },
      p: {
        marginBottom: '1rem',
        color: 'gray.700',
      },
      a: {
        color: 'green.700',
        textDecoration: 'none',
      },
    },
  },
  textStyles: {
    heading: {
      color: 'gray.900',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: 'gray.900',
    },
    h3: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'gray.900',
    },
    sm: {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '20px',
    },
  },
  layerStyles: {
    floating: {
      boxShadow: 'soft',
    },
  },
  components: {
    Alert,
    Avatar,
    Button,
    Card,
    Checkbox,
    Drawer,
    Input,
    Link,
    Modal,
    Radio,
    Spinner,
    Table,
    Tabs,
    Textarea,
    Tooltip,
    Select,
    Popover,
  },
});
