import useSWR from 'swr';
import { trpc } from '../config/trpc';

export type Org = {
  name: string;
  insightId: number;
};

export default function useOrgsData() {
  const { data, error, isValidating } = useSWR<Org[]>('useOrgsData', async () =>
    trpc.user.getOrgsForCurrentUser.query()
  );
  return {
    data,
    error,
    isValidating,
  };
}
