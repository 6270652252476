import { createTRPCProxyClient, httpLink, TRPCClientError } from '@trpc/client';
import type { AppRouter, AppDataRouter } from '@webapp/bff';
import superjson from 'superjson';
import { backOff } from 'exponential-backoff';
import { envConfig } from './envConfig';

export class ServerError extends TRPCClientError<AppRouter> {
  public code: number;

  public response: any;

  constructor(code: number, message: string, response: any) {
    super(message);
    this.code = code;
    this.response = response;
    Object.setPrototypeOf(this, ServerError.prototype);
  }
}

export const trpc = createTRPCProxyClient<AppRouter>({
  transformer: superjson,
  links: [
    httpLink({
      url: `${envConfig.API_URL}/bff/trpc`,
      fetch: async (url, options) =>
        backOff(
          async () => {
            const fetchResponse = await fetch(url, {
              ...options,
              credentials: 'include',
            });
            if (!fetchResponse.ok) {
              throw new ServerError(
                fetchResponse.status,
                fetchResponse.statusText,
                fetchResponse
              );
            }
            return fetchResponse;
          },
          {
            retry: (e: any) => (e.code ?? 500) >= 500,
            numOfAttempts: 3,
            startingDelay: Math.floor(Math.random() * 1000),
            delayFirstAttempt: false,
          }
        ),
      headers(opts) {
        const token = opts.op?.context?.token;
        const authHeader = token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {};
        return authHeader;
      },
    }),
  ],
});

export const dataTrpc = createTRPCProxyClient<AppDataRouter>({
  links: [
    httpLink({
      url: `${envConfig.API_URL}/bff/data`,
      fetch(url, options) {
        return backOff(
          async () => {
            const fetchResponse = await fetch(url, {
              ...options,
              credentials: 'include',
            });
            if (!fetchResponse.ok) {
              throw new ServerError(
                fetchResponse.status,
                fetchResponse.statusText,
                fetchResponse
              );
            }
            return fetchResponse;
          },
          {
            retry: (e: any) => (e.code ?? 500) >= 500,
            numOfAttempts: 3,
            startingDelay: Math.floor(Math.random() * 1000),
            delayFirstAttempt: false,
          }
        );
      },
    }),
  ],
});
