/* eslint-disable no-param-reassign */
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import ExportHeatmap from 'highcharts/modules/heatmap';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import HighchartsReact, {
  HighchartsReactProps,
} from 'highcharts-react-official';
import Annotations from 'highcharts/modules/annotations';
import Xrange from 'highcharts/modules/xrange';
import { ReactNode, useRef } from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';
import { exportWithCustomFonts } from '../../utilities/highcharts';
import moovenImage from '../../images/mooven-logo-grey.png';

const chartTextColor = '#000000';

Exporting(Highcharts);
ExportData(Highcharts);
ExportHeatmap(Highcharts);
OfflineExporting(Highcharts);
Annotations(Highcharts);
Xrange(Highcharts);

exportWithCustomFonts(Highcharts);

Highcharts.setOptions({
  chart: {
    spacingTop: 24,
    spacingRight: 24,
    spacingBottom: 7,
    spacingLeft: 24,
    height: 500,
    backgroundColor: 'white',
    style: {
      fontFamily: '"MoovenFont", "Helvetica", sans-serif',
      fontSize: '16px',
    },
  },
  title: {
    align: 'left',
    margin: 24,
    style: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },
  subtitle: {
    align: 'left',
    style: {
      fontSize: '14px',
      fontWeight: 'normal',
      color: '#727272',
    },
  },
  xAxis: {
    tickColor: '#F5F5F5',
    tickWidth: 2,
    lineWidth: 0,
    minPadding: 0,
    maxPadding: 0,
    crosshair: {
      snap: true,
    },
    title: {
      style: {
        color: chartTextColor,
      },
    },
    labels: {
      distance: 8,
      style: {
        color: chartTextColor,
        fontSize: '12px',
      },
    },
  },
  yAxis: {
    gridLineColor: 'rgba(0, 0, 0, 0.08)',
    gridLineDashStyle: 'ShortDot',
    gridLineWidth: 2,
    startOnTick: false,
    title: {
      text: undefined,
      style: {
        fontSize: '12px',
        color: chartTextColor,
      },
    },
    labels: {
      style: {
        color: chartTextColor,
        fontSize: '12px',
      },
    },
  },
  legend: {
    margin: 15,
  },
  tooltip: {
    shared: true,
    split: false,
    enabled: true,
    style: {
      zIndex: 50,
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    line: {
      marker: {
        enabled: false,
      },
      softThreshold: false,
      lineWidth: 3,
    },
  },
  navigation: {
    buttonOptions: {
      theme: {
        fill: 'white',
        stroke: '#EAEEF2',
        // @ts-ignore - using undocumented properties as documented properties (under exporting > buttons > contextButton) don't work. Taken from highcharts example https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/navigation/buttonoptions-theme/
        height: 21,
        r: 6,
        states: {
          hover: {
            fill: '#EEF2F5',
          },
          select: {
            fill: '#EAEEF2',
          },
        },
      },
    },
  },
});

export interface ChartProps {
  eventTracker?: (
    eventName: string,
    eventData?: { [key: string]: any }
  ) => void;
  overlayComponent?: ReactNode;
  options: HighchartsReactProps['options'];
  callback?: HighchartsReactProps['callback'];
}

export default function Chart({
  eventTracker,
  overlayComponent,
  options = {},
  callback,
}: ChartProps) {
  const portalDomNodeRef = useRef<HTMLElement>(null);
  if (!portalDomNodeRef.current) {
    portalDomNodeRef.current = document.createElement('div');
    portalDomNodeRef.current.className = styles.chartOverlayContainer;
  }
  const chartInstanceRef = useRef<Highcharts.Chart>(null);
  const chartRenderRef = useRef<ReactNode>(null);
  // using undocumented property to check if export menu is currently open
  // @ts-ignore
  if (!chartInstanceRef.current?.openMenu || !chartRenderRef.current) {
    chartRenderRef.current = (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...options,
            chart: {
              ...(options.chart || {}),
              events: {
                ...(options.chart?.events || {}),
                // Using load event instead of callback prop from component, as react callback prop has a bug
                // where it will also invoke callback function on export, passing a temporary chart instance
                // which is destroyed right after - https://www.highcharts.com/forum/viewtopic.php?t=45904
                load(loadEvent: Event) {
                  const chartInstance = this as unknown as Highcharts.Chart;
                  if (callback) {
                    callback(chartInstance);
                  }
                  if (options.chart?.events?.load) {
                    options.chart?.events?.load.apply(chartInstance, [
                      loadEvent,
                    ]);
                  }
                },
                render(renderEvent: Event) {
                  const chartInstance = this as unknown as Highcharts.Chart;
                  chartInstanceRef.current = chartInstance;
                  updateContentOverlay(
                    chartInstance,
                    portalDomNodeRef.current!
                  );
                  if (options.chart?.events?.render) {
                    options.chart?.events?.render.apply(chartInstance, [
                      renderEvent,
                    ]);
                  }
                },
                exportData(event: Highcharts.ExportDataEventObject) {
                  const functionContext = this as unknown as Highcharts.Chart;
                  if (eventTracker) {
                    eventTracker('Chart Exported Data', {
                      referrer: 'Chart',
                      chartTitle: functionContext.options.title?.text,
                    });
                  }
                  options.chart?.events?.exportData?.apply(functionContext, [
                    event,
                  ]);
                },
              },
            },
            exporting: {
              enabled: true,
              ...(options.exporting || {}),
              buttons: {
                ...(options.exporting?.buttons || {}),
                contextButton: {
                  text: 'Export',
                  symbol: 'triangle-down',
                  symbolSize: 3,
                  symbolY: 15,
                  x: 0,
                  ...(options.exporting?.buttons?.contextButton || {}),
                },
              },
              chartOptions: {
                ...(options.exporting?.chartOptions || {}),
                chart: {
                  width: 1300,
                  height: 600,
                  ...(options.exporting?.chartOptions?.chart || {}),
                  events: {
                    ...(options.exporting?.chartOptions?.chart?.events || {}),
                    load(event: Event) {
                      const functionContext =
                        this as unknown as Highcharts.Chart;
                      if (eventTracker) {
                        eventTracker('Chart Exported Image', {
                          referrer: 'Chart',
                          chartTitle: functionContext.options.title?.text,
                        });
                      }
                      functionContext.renderer
                        .image(moovenImage, 1150, 20, 120, 30)
                        .add();
                      options.exporting?.chartOptions?.chart?.events?.load?.apply(
                        functionContext,
                        [event]
                      );
                    },
                  },
                },
              },
            },
          }}
        />
        {ReactDOM.createPortal(overlayComponent, portalDomNodeRef.current!)}
      </>
    );
  }
  return chartRenderRef.current;
}

function updateContentOverlay(
  chartInstance: Highcharts.Chart & {
    customContentOverlayRef?: HTMLElement;
  },
  portalNode: HTMLElement
) {
  if (chartInstance.customContentOverlayRef !== portalNode) {
    chartInstance.customContentOverlayRef = portalNode;
    chartInstance.container.append(portalNode);
  }
  portalNode.style.top = `${chartInstance.plotTop}px`;
}
