import MapOptions = google.maps.MapOptions;

export const MAP_DEFAULT_CENTER = {
  lat: -36.85014959834592,
  lng: 174.76223350114168,
};

export const MAP_CONTAINER_STYLING = {
  width: '100%',
  height: '100%',
};

const MAP_DEFAULT_STYLE = [
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#56766a',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text',
    stylers: [
      {
        lightness: 65,
      },
      {
        weight: 0.5,
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#e6f4f0',
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        color: '#a1ceaf',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.business',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.school',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#bbe2c6',
      },
      {
        lightness: 35,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.icon',
    stylers: [
      {
        lightness: 30,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#84b8f0',
      },
      {
        lightness: -5,
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#84b8f0',
      },
      {
        lightness: -25,
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#a1ceaf',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.icon',
    stylers: [
      {
        color: '#a1ceaf',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#d5f0dc',
      },
      {
        lightness: -10,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#84b8f0',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#84b8f0',
      },
      {
        lightness: -15,
      },
    ],
  },
];

export const MAP_FEATURE_OPTIONS: MapOptions = {
  minZoom: 6,
  styles: MAP_DEFAULT_STYLE,
  mapTypeControlOptions: {
    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
    position: window.google.maps.ControlPosition.TOP_LEFT,
    mapTypeIds: [
      window.google.maps.MapTypeId.ROADMAP,
      window.google.maps.MapTypeId.SATELLITE,
      window.google.maps.MapTypeId.HYBRID,
    ],
  },
  zoomControlOptions: {
    position: google.maps.ControlPosition.LEFT_BOTTOM,
  },
  streetViewControlOptions: {
    position: google.maps.ControlPosition.LEFT_BOTTOM,
  },
  gestureHandling: 'cooperative',
};

const FORWARD_ARROW = 'M-20 20 0 6 0 6 20 20';
const WHITE_TRIANGLE = 'M-10 18 0 8.5 0 8.5 10 18 10 18 -10 18';

export const POLYLINE_ICONS = [
  {
    icon: {
      path: FORWARD_ARROW,
      strokeColor: '#FFFFFF',
      strokeOpacity: 1,
      scale: 0.2,
      strokeWeight: 3,
    },
    offset: '0px',
    repeat: '50px',
  },
  {
    icon: {
      path: WHITE_TRIANGLE,
      strokeColor: '#FFFFFF',
      fillColor: '#FFFFFF',
      fillOpacity: 1,
      strokeOpacity: 1,
      scale: 0.2,
      strokeWeight: 3,
    },
    offset: '0px',
  },
];

export const DIRECTIONS_RENDERER_OPTIONS = {
  preserveViewport: true,
  draggable: true,
  suppressInfoWindows: true,
  polylineOptions: {
    zIndex: 999,
    strokeColor: '#0088FF',
    strokeWeight: 6,
    strokeOpacity: 0.6,
  },
};

export const WAYPOINT_MARKER_ICON = {
  path: 'M 9,9 m -9,0 a 9,9 0 1,0 18,0 a 9,9 0 1,0 -18,0',
  fillColor: 'white',
  fillOpacity: 1,
  strokeWeight: 3,
  strokeColor: 'black',
  labelOrigin: window.google ? new window.google.maps.Point(9, 9) : undefined,
  anchor: window.google ? new window.google.maps.Point(9, 9) : undefined,
};
