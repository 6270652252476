import useSWR from 'swr';
import type { OrgConfig } from '@webapp/bff/src/types/site';
import { trpc } from '../config/trpc';

export type InsightDetails = OrgConfig;

export default function useInsightData(insightId?: string) {
  const { data, error, isValidating } = useSWR<InsightDetails>(
    insightId
      ? {
          keyName: 'useInsightData',
          insightId,
        }
      : null,
    (swrParams) =>
      trpc.site.getOrgConfigByOrgId.query({ orgId: swrParams.insightId })
  );

  return {
    data,
    error,
    isValidating,
  };
}
