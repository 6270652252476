import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouteError } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { errorReport } from '../utils/errors';
import { MESSAGE_GENERIC_ERROR } from '../constants/messages';
import Spinner from './Spinner';

export default function RouteErrorBoundary() {
  const { formatMessage } = useIntl();
  const error = useRouteError();
  const [visibleError, setVisibleError] = useState<any | undefined>();

  useEffect(() => {
    if (!handleLazyLoadError(error)) {
      errorReport.unknown(error);
      setVisibleError(error);
    }
  }, [error]);

  if (!visibleError) {
    return (
      <Flex justify="center" align="center">
        <Spinner />
      </Flex>
    );
  }
  return (
    <Box data-testid="error-block" px={12} py={20} textAlign="center">
      {formatMessage(MESSAGE_GENERIC_ERROR)}
    </Box>
  );
}

function handleLazyLoadError(error) {
  if (
    error instanceof Error &&
    error.message.includes('Failed to fetch dynamically imported module') &&
    error.message.includes('mooven-chunk') &&
    localStorage.getItem('refreshKey') !== error.message
  ) {
    // save file reference to localStorage to avoid infinite loop
    localStorage.setItem('refreshKey', error.message);
    errorReport.log(error);
    window.location.reload();
    return true;
  }
  return false;
}
