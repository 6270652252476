import { trpc } from '../config/trpc';

export type AuthResponse = {
  authenticated: boolean;
  error?: string;
  status?: number;
  message?: string;
  roles?: string[];
};

export async function login(
  username: string,
  password: string,
  fingerprint: string
) {
  const response = await trpc.auth.login.mutate({
    email: username,
    password,
    fingerprint,
  });

  return response;
}

export async function logout() {
  await trpc.auth.logout.mutate();
  return true;
}

export async function submitMFA(mfaCode: string, fingerprint: string | null) {
  const response = await trpc.auth.submitMfaCode.mutate({
    mfaCode,
    fingerprint: fingerprint || undefined,
  });
  return response;
}

export async function requestNewMFACode() {
  const response = await trpc.auth.resendMfaCode.mutate();
  return response;
}

export async function requestPasswordReset(email: string) {
  return trpc.user.requestPasswordReset.mutate({ email });
}

export async function resetPassword(token: string, password: string) {
  const response = trpc.onboard.setPassword.mutate(
    { password },
    {
      context: {
        token,
      },
    }
  );
  return response;
}

export async function requestCodeByEmail() {
  await trpc.user.sendMfaCodeByEmail.mutate();
}
