import { ReactNode } from 'react';
import { SWRConfig } from 'swr';
import { useLocation, useNavigate } from 'react-router';
import { SWRDevTools } from 'swr-devtools';
import { errorReport } from '../utils/errors';
import { pathLogin } from '../constants/path';

export function ConfiguredSWRWrapper({ children }: { children?: ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <SWRDevTools>
      <SWRConfig
        value={{
          dedupingInterval: 500,
          focusThrottleInterval: 500,
          onError: (error, key) => {
            if (
              error.code === 401 ||
              error.status === 401 ||
              error.message === 'Unauthorized'
            ) {
              navigate(pathLogin(), {
                replace: true,
                state: { redirectAfterLogin: location },
              });
            }
            if ((error.status ?? 500) >= 500) {
              errorReport.critical(error, {
                key,
                message: error.message,
                status: error.status,
              });
            }
          },
        }}
      >
        {children}
      </SWRConfig>
    </SWRDevTools>
  );
}
