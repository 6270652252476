import useSWR from 'swr';
import { RouteInsightData } from '@webapp/bff/src/types/route-insights';
import { dataTrpc } from '../config/trpc';

export type RouteInsights = {
  routeName: string;
  siteId: number;
  siteUnit: 'IMPERIAL' | 'METRIC';
  timeZone: string;
  insights: RouteInsightData[];
};

export default function useRouteInsightsData(
  trackId: string,
  insightId: string,
  siteId: string
) {
  const shouldFetch = trackId && siteId;
  const { data, error, isValidating } = useSWR<RouteInsights>(
    shouldFetch ? `useRouteInsightsData/${insightId}/${trackId}` : null,
    () =>
      dataTrpc.routeInsights.get.query({
        passageId: trackId,
        orgId: insightId,
        siteId: `${siteId}`,
      }) as Promise<RouteInsights>,
    {
      refreshInterval: 5 * 60 * 1000,
    }
  );
  return {
    data: shouldFetch ? data : undefined,
    error,
    isValidating,
  };
}
