import { useSWRConfig } from 'swr';
import { Spinner, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { performLogout } from '../../utils/auth';

export default function Logout() {
  const { mutate } = useSWRConfig();
  const { boot, shutdown } = useIntercom();
  const [loggedOut, setLoggedOut] = useState(false);
  useEffect(() => {
    Promise.all([performLogout(mutate), shutdown(), boot()]).finally(() => {
      setLoggedOut(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!loggedOut) {
    return (
      <Flex
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        p={10}
      >
        <Spinner variant="mooven" size="xl" />
      </Flex>
    );
  }
  return <Navigate to="/login" />;
}
