import {
  BoxProps,
  InputGroup as ChakraInputGroup,
  InputLeftElement,
  InputRightElement,
  ThemingProps,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react';
import type { ReactElement, ReactNode, Ref } from 'react';

type InputGroupProps = BoxProps & {
  startElement?: ReactNode;
  endElement?: ReactNode;
  startElementProps?: BoxProps & { ref?: Ref<HTMLDivElement> };
  endElementProps?: BoxProps & { ref?: Ref<HTMLDivElement> };
  startAddon?: ReactElement<any>;
  endAddon?: ReactElement<any>;
  children: ReactElement<any>;
  // Chakra 2 prop, to be replaced by input size css token:
  size?: ThemingProps['size'];
} & (
    | {
        startElement?: ReactNode;
        startAddon?: never;
      }
    | {
        startElement?: never;
        startAddon?: ReactElement;
      }
  ) &
  (
    | {
        endElement?: ReactNode;
        endAddon?: never;
      }
    | {
        endElement?: never;
        endAddon?: ReactElement;
      }
  );

export default function InputGroup({
  startElement,
  startAddon,
  endElement,
  endAddon,
  children,
  startElementProps,
  endElementProps,
  ...rest
}: InputGroupProps) {
  return (
    <ChakraInputGroup {...rest}>
      {startElement && (
        <InputLeftElement pointerEvents="none" {...startElementProps}>
          {startElement}
        </InputLeftElement>
      )}
      {startAddon && (
        <InputLeftAddon as={startAddon.type} {...startAddon.props} />
      )}
      {children}
      {endElement && (
        <InputRightElement pointerEvents="none" {...endElementProps}>
          {endElement}
        </InputRightElement>
      )}
      {endAddon && <InputRightAddon as={endAddon.type} {...endAddon.props} />}
    </ChakraInputGroup>
  );
}
