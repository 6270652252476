import { createContext } from 'react';

export interface AuthContextType {
  user: {
    legacyUserId: number;
    userId: string; // we should use this for everything
    email: string;
    name: string;
    firstName: string;
    lastName: string;
    isAdmin: boolean; // system wide admin
    customerData: {
      externalId: string;
      customerName: string;
    }[];
    orgAccess: {
      canEdit: string[];
      canEditAll: boolean;
    };
    sessionState:
      | 'onboarding:invited'
      | 'onboarding:missing_phone_number'
      | 'onboarding:waiting_phone_confirmation'
      | 'waiting_mfa_code'
      | 'invalid'
      | 'active';
  } | null;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
});
